import { db } from "$lib/firebase/init";
import authStore from "$lib/stores/authStore";
import { LocationSearchType, type User } from "$lib/types";
import setTheme from "$lib/utils/setTheme";
import { doc, GeoPoint, getDoc, setDoc, updateDoc } from "firebase/firestore";
import posthog from "posthog-js";
import { auth, facebookProvider, googleProvider, twitterProvider } from "./init";

import { goto } from "$app/navigation";
import { getLocationData, locationAutoComplete, locationGeoCode } from "$lib/utils/locationSearch";
import {
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

export const getCookie = (name) => {
  const cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie ? cookie[2] : null;
};

export async function loginEmailandPassowrd(email: string, password: string) {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    posthog.capture("User Login", {
      email: email,
      type: "email",
    });

    const profile = await getDoc(doc(db, "users", user.user.uid));
    const profileData = profile.data();
    if (!profileData) {
      await createUser(user.user);
    }
  } catch (e) {
    return e;
  }
}

export async function googleLogin() {
  try {
    const user = await signInWithPopup(auth, googleProvider, browserPopupRedirectResolver);
    posthog.capture("User Login", {
      email: user.user.email,
      type: "google",
    });

    const profile = await getDoc(doc(db, "users", user.user.uid));
    const profileData = profile.data();
    if (!profileData) {
      await createUser(user.user);
    }
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function facebookLogin() {
  try {
    const user = await signInWithPopup(auth, facebookProvider, browserPopupRedirectResolver);
    posthog.capture("User Login", {
      email: user.user.email,
      type: "facebook",
    });

    const profile = await getDoc(doc(db, "users", user.user.uid));
    const profileData = profile.data();
    if (!profileData) {
      await createUser(user.user);
    }
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function twitterLogin() {
  try {
    const user = await signInWithPopup(auth, twitterProvider, browserPopupRedirectResolver);
    posthog.capture("User Login", {
      email: user.user.email,
      type: "twitter",
    });
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function googleRegister() {
  try {
    const googleAuth = await signInWithPopup(auth, googleProvider, browserPopupRedirectResolver);
    posthog.capture("User Registered", {
      email: googleAuth.user.email,
      type: "google",
    });
    await createUser(googleAuth.user);
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function facebookRegiser() {
  try {
    const facebookAuth = await signInWithPopup(
      auth,
      facebookProvider,
      browserPopupRedirectResolver
    );
    posthog.capture("User Registered", {
      email: facebookAuth.user.email,
      type: "facebook",
    });
    await createUser(facebookAuth.user);
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function twitterRegiser() {
  try {
    const twitterAuth = await signInWithPopup(auth, twitterProvider, browserPopupRedirectResolver);
    posthog.capture("User Registered", {
      email: twitterAuth.user.email,
      type: "twitter",
    });
    await createUser(twitterAuth.user);
  } catch (e) {
    console.log("Popup closed");
  }
}

export async function registerEmailandPassowrd(email: string, password: string) {
  try {
    const newUser: any = await createUserWithEmailAndPassword(auth, email, password);
    posthog.capture("User Registered", {
      email: email,
      type: "email",
    });
    await createUser(newUser.user);
  } catch (e) {
    return e;
  }
}

export async function fetchSessionCookie(idToken: string) {
  const csrfToken = getCookie("csrfToken") || "";

  await fetch("/api/auth/session-cookie", {
    method: "POST",
    body: JSON.stringify({ idToken, csrfToken }),
    headers: {
      contentType: "application/json",
    },
  });
}

export async function createUser(user) {
  const locData = await getLocationData();

  await setDoc(doc(db, "users", user.uid), {
    bio: "",
    onboardingComplete: false,
    photoURL: user.photoURL,
    language: "English",
    location: locData.locString,
    city: locData?.city || null,
    region: locData?.region_code || null,
    country: locData?.country || null,
    countryCode: locData?.country_code || null,
    public: true,
    privacy: "everyone",
    followerPolicy: "everyone",
    favoritesPolicy: "enable",
    units: "imperial",
    notificationSettings: {
      favoriteJoinRide: true,
      favoriteJoinGroup: true,
      followRequest: true,
      followApprove: true,
      rideInvite: true,
      rideDiscussion: true,
      groupUpdated: true,
      rideUpdated: true,
      groupRide: true,
      groupInvite: true,
      groupJoinRequest: true,
      groupDiscussion: true,
      groupApproved: true,
      groupUserLimit: true,
    },
    totalRides: 0,
    theme: `${window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"}`,
    geoPoint: new GeoPoint(locData?.latitude, locData?.longitude),
  });

  // Redirecting too quickly does not reflect document updates
  // await new Promise((f) => setTimeout(f, 4000));
  let profile = await getProfile(user);
  profile.uid = user.uid;
  authStore.update((state) => ({ ...state, profile }));
}

export async function updateProfile(userId, updatedProfile, updateState = true) {
  await updateDoc(doc(db, "users", userId), updatedProfile);

  if (updatedProfile.location) {
    localStorage.setItem("location", updatedProfile.location);
    const loc = await locationAutoComplete(updatedProfile.location, LocationSearchType.city);
    const geo = await locationGeoCode(loc?.[0]?.placeId);
    if (geo) localStorage.setItem("geoCode", JSON.stringify(geo));
  }

  if (updateState)
    authStore.update((state) => ({ ...state, profile: { ...state.profile, ...updatedProfile } }));
}

export async function getProfile(user): Promise<User> {
  const profile = await getDoc(doc(db, "users", user.uid));
  const profileData = profile.data();

  if (profileData?.theme) setTheme(profileData.theme);

  return profile.data() as User;
}

export async function getFollowingDetails(user) {
  const followingRequests = await getDoc(
    doc(db, `users/${user.uid}/followDetails`, "followDetails")
  );
  if (followingRequests.exists()) {
    return followingRequests.data();
  } else {
    await setDoc(doc(db, `users/${user.uid}/followDetails`, "followDetails"), {
      followingRequests: [],
      followerRequests: [],
      followers: [],
      following: [],
    });
    return {
      followingRequests: [],
      followerRequests: [],
      followers: [],
      following: [],
    };
  }
}

export async function signOut() {
  await goto("/");
  posthog.reset();
  await auth.signOut();
  await fetch("/api/auth/logout");
}
