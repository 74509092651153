import type { FieldValue } from "firebase-admin/firestore";
import type { GeoPoint, Timestamp } from "firebase/firestore";

export interface User {
  accessToken: string;
  uid: string;
  bio?: string;
  birthday?: Timestamp;
  city?: string;
  country?: string;
  countryCode?: string;
  createdAt: Timestamp;
  email: string;
  emailVerified: boolean;
  firstName: string;
  gender?: string;
  geoPoint?: GeoPoint;
  language?: string;
  lastName: string;
  location?: string;
  units?: string;
  name: string;
  onboardingComplete: boolean;
  photoURL?: string;
  public?: boolean;
  privacy: string;
  followRequests?: Array<string>;
  totalRides?: number;
  tier: string;
  region: string;
  shortId: string;
  theme?: string;
}

export interface CreateRide {
  headerImageUrl?: string;
  name: string;
  date: string;
  time: string;
  address: string;
  placeId: string;
  summary: string;
  details?: string;
  routeUrl?: string;
  distance?: number;
  elevation?: number;
  category?: string;
  type?: string;
  effort?: string;
  public: boolean;
  website?: string;
}

export interface RideDocument {
  id?: string;
  shortId: string;
  ownerId: string;
  ownerShortId: string;
  ownerName: string;
  ownerPhotoUrl: string;
  ownerTier: string;
  ownerLocation: string;
  groupId?: string;
  groupShortId?: string;
  groupName?: string;
  groupHeaderUrl?: string;
  location: any;
  address: string;
  attendees: Array<any>;
  memberDetails: Array<any>;
  date: any;
  recurring: boolean;
  title: string;
  headerImageUrl: string;
  summary: string;
  routeUrl: string;
  distance: number;
  elevation: number;
  details: string;
  category: string;
  type: string;
  effort: string;
  public: boolean;
  website: string;
  createdAt: FieldValue;
}

export interface GroupDocument {
  id: string;
  shortId: string;
  ownerId: string;
  ownerShortId: string;
  ownerName: string;
  ownerPhotoUrl: string;
  ownerTier: string;
  ownerLocation: string;
  geopoint: GeoPoint;
  invited?: Array<string>;
  invitedExternal?: Array<string>;
  location: string;
  placeId: string;
  memberDetails?: Array<any>;
  name: string;
  headerImageUrl?: string;
  summary: string;
  details: string;
  category: string;
  type: string;
  privacy: string;
  public: boolean;
  website?: string;
  createdAt: FieldValue;
}

export interface RideSearchFilters {
  location: string;
  distance: string;
  search: string;
  date: string;
  effort: string;
  category: string;
  type: string;
  drop: boolean;
  event: boolean;
  sort: string;
}

export interface CyclistSearchFilters {
  location: string;
  distance: string;
  search: string;
}

export const enum LocationSearchType {
  city = "city",
  address = "address",
}

export type DropdownOption = {
  name: string;
  value: string;
  tier?: string;
  description?: string;
};
