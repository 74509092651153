import { fetchSessionCookie, getFollowingDetails, getProfile } from "$lib/firebase/auth";
import { auth } from "$lib/firebase/init";
import type { User } from "$lib/types";
import { writable } from "svelte/store";

const authStore = writable<{
  isLoggedIn: boolean;
  user?: User;
  firebaseControlled: boolean;
  profile?: any;
  followDetails?: any;
}>({
  isLoggedIn: false,
  firebaseControlled: false,
});

auth.onAuthStateChanged(async (user) => {
  let profile = user ? await getProfile(user) : null;
  let followDetails = user ? await getFollowingDetails(user) : null;
  if (profile && user) profile.uid = user.uid;

  authStore.set({
    isLoggedIn: user !== null,
    user,
    firebaseControlled: true,
    profile,
    followDetails,
  });
  if (user) {
    user.getIdToken(true);
    await fetchSessionCookie(user.accessToken);
  }
});

export default {
  subscribe: authStore.subscribe,
  set: authStore.set,
  update: authStore.update,
};
