import { LocationSearchType } from "$lib/types";
import { GeoPoint } from "firebase/firestore";

interface LocationData {
  continent: string;
  continent_code: string;
  country: string;
  country_code: string;
  region: string;
  region_code: string;
  city: string;
  locString: string;
  latitude: number;
  longitude: number;
}

export async function getLocationData(): Promise<LocationData> {
  const locationReq = await fetch("https://ipwho.is", { mode: "cors" });
  let locData = await locationReq?.json();
  // Local testing
  if (locData?.message == "You've hit the monthly limit") {
    locData = {
      continent: "North America",
      continent_code: "NA",
      country: "United States",
      country_code: "US",
      region: "Missouri",
      region_code: "MO",
      city: "Kansas City",
      latitude: 39.0997265,
      longitude: -94.5785667,
    };
  }

  locData.locString = `${locData?.city}, ${locData?.region_code}, ${locData?.country_code}`;

  return locData;
}

export async function getLocationStorage() {
  if (
    !("location" in localStorage) ||
    !("geoCode" in localStorage) ||
    localStorage.getItem("location") === "undefined"
  ) {
    const locData = await getLocationData();
    if (locData?.locString) {
      localStorage.setItem("location", locData.locString);
      const loc = await locationAutoComplete(locData.locString, LocationSearchType.city);

      if (loc?.[0]?.placeId) {
        const geo = await locationGeoCode(loc?.[0].placeId);
        if (geo) localStorage.setItem("geoCode", JSON.stringify(geo));
      }
    }
  }

  return {
    location: localStorage.getItem("location"),
    geoCode: JSON.parse(localStorage.getItem("geoCode")),
  };
}

export async function locationAutoComplete(input, type: LocationSearchType) {
  if (input) {
    try {
      const locRequest = await fetch("/api/location", {
        method: "POST",
        body: JSON.stringify({ search: input, type }),
        headers: {
          contentType: "application/json",
        },
      });
      if (locRequest.ok) {
        const location = await locRequest.json();
        return location.locations;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  } else return [];
}

export async function locationGeoCode(placeId) {
  try {
    const request = await fetch(`/api/geocode`, {
      method: "POST",
      body: JSON.stringify({ placeId: placeId }),
      headers: {
        contentType: "application/json",
      },
    });

    const geocode = await request.json();
    return new GeoPoint(geocode?.location?.lat, geocode?.location?.lng);
  } catch (e) {
    console.log(e);
  }
}
