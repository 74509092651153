export default function setTheme(theme: string) {
  const htmlTag = document.getElementsByTagName("html")[0];
  if (theme === "dark") {
    localStorage.setItem("color-theme", "dark");
    document.documentElement.classList.add("dark");
    htmlTag.setAttribute("data-theme", "dark");
  } else if (theme === "light") {
    localStorage.setItem("color-theme", "light");
    document.documentElement.classList.remove("dark");
    htmlTag.setAttribute("data-theme", "light");
  }
}
